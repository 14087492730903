import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`It's All About Taking The Right Risks`}</h1>
    <p>{`Sorry y'all, today was a super busy day...so we're switching things up this week with our post and update schedule.`}</p>
    <p><strong parentName="p">{`Fridays are normally our off day, but instead we've got something good coming your way tomorrow!`}</strong></p>
    <p>{`In the meantime, i attached a memo that i sent to a group of CEOs and thought you might like it...it's all about how I think about `}<strong parentName="p">{`risk`}</strong>{`.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`CEO Memo: How I Think About Risk`}</strong></p>
    <p><strong parentName="p">{`This week I want to talk about risk.`}</strong></p>
    <p>{`Are you a risk taker?`}</p>
    <p>{`When I ask a room full of people that question - it’s like asking “what’s your craziest sexual fantasy?” - a different image pops into each person’s brain.`}</p>
    <p>{`Some people imagine life or death risks like playing Russian roulette or strapping on one of these flying squirrel suits and jumping off a mountain.`}</p>
    <p>{`Some think of social risks, like coming out of the closet, or admitting an embarrassing secret.`}</p>
    <p>{`Some people think of career risks - like quitting a stable job to start a company.`}</p>
    <p><strong parentName="p">{`What pops into my head is a different kind of risk. The only risk that matters to me is Mediocrity.`}</strong></p>
    <p>{`Why? Because mediocrity drains your most precious resource (time), for a minimal reward. Being mediocre saps me of my motivation in a slow, gradual way.`}</p>
    <p>{`I’d rather fail quickly. Failure is painful, but brief. Failure may bruise my ego, but it doesn’t steal my most valuable asset (time).`}</p>
    <p><strong parentName="p">{`It’s not just in business.`}</strong></p>
    <p>{`Who here has stayed in a mediocre relationship for too long? Because it’s not that bad.`}</p>
    <p>{`Who has spent years working at a stable, but a mediocre job to ‘save up a bit of money’ or ‘get a bit more experience’ before going to do the thing you actually want to do.`}</p>
    <p>{`Nobody plans on being mediocre - it just happens. It happens because we avoid stretching ourselves further. It happens because we settle for comfort over striving for greatness. And mediocrity creeps up on us slowly, dragging us down inch by inch, over a long period of time.`}</p>
    <p><strong parentName="p">{`Mediocrity is to be avoided at all costs.`}</strong></p>
    <p>{`As the CEO of your company, you’re the captain of your ship. And yes, like the Titanic, there are some icebergs that you need to avoid. Those icebergs could be competitors, or lack of cashflow. Don’t let those sink your ship.`}</p>
    <p>{`But most of the time, there is no big iceberg to swerve away from. Instead, water is slowly rising, inch by inch, sinking your ship silently.`}</p>
    <p>{`Don’t settle for mediocre employees. Do you have any of them at your company? Cut them out - think of mediocrity as a disease.`}</p>
    <p>{`Don’t settle for building a mediocre product - ask yourself, what can we do to make this amazing? What can we do that would blow away our customers next month? That’s the new standard for your product discussions.`}</p>
    <p>{`Don’t be fooled by market risk, environmental risk, technical risk. They are all toothless compared to the invisible monster of mediocrity.`}</p>
    <p><strong parentName="p">{`Those other risks can hurt you, but they won’t zap you of your superpowers the way mediocrity will over time.`}</strong></p>
    <p>{`For all of us that are privileged enough to not be fending for our lives, with all 4 limbs and functioning brains. For anyone who’s got a roof over their head and enough food on the table. The real risk for most of us is being mediocre!`}</p>
    <p><strong parentName="p">{`Recognize it, and avoid it!`}</strong></p>
    <p>{`-shaan`}</p>
    <PostFooter prevButton={{
      text: postMap.bankshots_only_work_in_basketball.label,
      slug: slugify(postMap.bankshots_only_work_in_basketball.slug)
    }} nextButton={{
      text: postMap.hitting_a_plateau_should_be_like_greeting_an_old_friend.label,
      slug: slugify(postMap.hitting_a_plateau_should_be_like_greeting_an_old_friend.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      